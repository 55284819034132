import React from 'react';
import Story from "../Component/Layout/Story";
import Footer from "../Component/Layout/Footer";
import Layout from "../Component/Layout/Layout";
import CasePromoServices from "../Component/website/CasePromoServices";
import servicesCover from "../assets/images/services-cover.webp";
import ServicePartner from '../assets/images/services-partner.webp';
import ServicesPromoCase from "../Component/website/ServicesPromoCase";
import casePromo1 from "../assets/images/case-promo-1.webp";
import casesCover5 from "../assets/images/cases-cover5.webp";

function Services() {
    return (
        <Layout>

            <div className="container">
                <div className={'services-text-container'}>
                    <p className={'services-heading'}>Redefining the way we buy online.</p>

                    <p className={'services-heading-text'}>
                        eCommerce has grown up. What used to be a top-tier online shopping experience, has turned into the bare
                        minimum
                        people expect. Now, your eCommerce website is the most important brand moment in the customer journey.
                        Brands
                        need
                        to deliver powerful eCommerce experiences that differentiate them from competitors and empowers their
                        customers.
                    </p>
                    <p className={'services-heading-text'}>
                        This means, brands need to have an eCommerce experience in place that attracts, engages, converts and
                        retains
                        customers. From effortless multilingual shopping to a unified omnichannel experience with the brand’s
                        purpose
                        at
                        its core.
                    </p>
                    <p className={'services-heading-text'}>
                        At Story of AMS, we put your customers at the center of everything we do. Combined with the right mix of
                        research,
                        design, content, and technology, we build and scale leading eCommerce brands. As a certified Shopify Plus
                        and
                        Storyblok partner, there is no challenge we walk away from.
                    </p>
                </div>

                <div className="row">
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <ServicesPromoCase
                            cardImg={casePromo1}
                            cardTitleMeta={'BOLS'}
                            cardTitle={'Goings direct to consumer with Shopify Plus'}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <ServicesPromoCase
                            cardImg={casesCover5}
                            cardTitleMeta={'Signify'}
                            cardTitle={'The new standard for Global eCommerce experiences'}
                        />
                    </div>
                </div>

                <div className="container">
                    <hr/>
                </div>

                <p className={'our-services-text'}>Our Services</p>

                <div className="row g-0">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                        <p className={'our-services-heading'}>Strategy</p>
                        <p className={'our-services-li'}>&#8226; Consumer Insights</p>

                        <p className={'our-services-li'}>&#8226; Competitor Analysis</p>

                        <p className={'our-services-li'}>&#8226; KPI Definition</p>

                        <p className={'our-services-li'}>&#8226; Positioning</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                        <p className={'our-services-heading'}>Design</p>
                        <p className={'our-services-li'}>&#8226; User Experience</p>

                        <p className={'our-services-li'}>&#8226; User Interface</p>

                        <p className={'our-services-li'}>&#8226; Brand Identity</p>

                        <p className={'our-services-li'}>&#8226; Design Systems</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                        <p className={'our-services-heading'}>Content</p>
                        <p className={'our-services-li'}>&#8226; Content Strategy</p>

                        <p className={'our-services-li'}>&#8226; Messaging + Voice</p>

                        <p className={'our-services-li'}>&#8226; Photography</p>

                        <p className={'our-services-li'}>&#8226; Video Production</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-2">
                        <p className={'our-services-heading'}>Technology</p>
                        <p className={'our-services-li'}>&#8226; Headless Shopify</p>

                        <p className={'our-services-li'}>&#8226; Web Development</p>

                        <p className={'our-services-li'}>&#8226; CMS Implementation</p>

                        <p className={'our-services-li'}>&#8226; Mobile Apps</p>
                    </div>
                </div>


                <p className={'our-partners-services mt-5'}>OUR PARTNERS</p>

                <div className="row g-0 px-5">
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>

                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 my-5">
                        <img src={ServicePartner} className={'service-partner-img'}/>
                    </div>
                </div>

            </div>


            <CasePromoServices
                promoTitle={'STOREFRONT AS A  SERVICE'}
                promoHeading={"Grow your business with a headless storefont"}
                promoFeatures={"We're on a mission to redefine how eCommerence brands,We're on a mission to redefine how eCommerence brands,We're on a mission to redefine how eCommerence brands,"}
                casePromoContainer={'case-promo-container-3'}
                columnClass={"col-xl-10 col-lg-11 col-md-12 col-sm-12 col-xs-12 col-12"}
                caseImg={servicesCover}
                casePromoTextContainerClass={'case-promo-text-container-3'}
                btnText={'Learn More'}
            />

        </Layout>
    );
}

export default Services;