import React from 'react';
import logo from '../../assets/images/logo.png';
import {useNavigate} from "react-router-dom";

function Navbar() {
    const navigate = useNavigate()

    return (
        <div className={'navbar navbar-lg-item'}>
            <img onClick={() => {navigate('/')}}
                src={logo} className={'navbar-logo'}/>

            <div className={'navbar-links-container'}>
                <p onClick={() => {navigate('/cases/')}}>Cases</p>
                <p onClick={() => {navigate('/services/')}}>Services</p>
                <p onClick={() => {navigate('/contact-us/')}}>Contact Us</p>
                <p onClick={() => {navigate('/about-us/')}}>About Us</p>
                {/*<div className={'navbar-links-container-separator'}/>*/}
            </div>
        </div>
    );
}

export default Navbar;