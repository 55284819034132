import React from 'react';
import styled, {keyframes} from 'styled-components';
import AnimationBtn from "../website/AnimationBtn";
import {useNavigate} from "react-router-dom";

const moveAnimation = keyframes`
  25% {
    opacity: 0.3;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const moveDownAnimation = keyframes`
  25% {
    opacity: 0.3;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroServiceBtn = styled.button`
  &:not(:hover) .hero-service-btn-text {
    animation: ${moveDownAnimation} 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }
  
  &:hover .hero-service-btn-text {
    animation: ${moveAnimation} 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }
`;


function HeroContent() {
    const navigate = useNavigate();

    return (
        <div className={'hero-content-inner-container'}>
            <div className="hero-text-container">
                <p className={'hero-text'}>
                    Building & Scaling Leadings
                </p>
                <p className={'hero-text'}>
                    <span style={{color: "rgb(24, 236, 192)"}}>eCommerce</span>&nbsp;
                    Brands
                </p>
            </div>

            <div className={'hero-btn-container'}>
                <AnimationBtn
                    text={"View out services"}
                    outerDiv={'hero-btn-service'}
                    btnClass={'hero-service-btn'}
                    btnDivClass={'hero-service-btn-div'}
                    href={'#services'}
                />
                <AnimationBtn
                    text={"Discover our work"}
                    outerDiv={'hero-btn-work'}
                    btnClass={'hero-work-btn'}
                    btnDivClass={'hero-work-btn-div'}
                    onClick={() => {navigate('/cases/')}}
                />
            </div>
        </div>
    );
}

export default HeroContent;