import React, {useRef} from 'react';
import AnimationBtn from "../website/AnimationBtn";
import {useNavigate} from "react-router-dom";
import ContactUsSwiper from "./ContactUsSwiper";
import emailjs from '@emailjs/browser';
import {toast} from "react-hot-toast";

function ContactUsBody() {
    const navigate = useNavigate();
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_cfkl1iq', 'template_0ll7498', form.current, 'ff8sWdtotRYGA0Rnn')
            .then((result) => {
                console.log(result.text);
                toast.success("Thank you for your message, we will be intouch with you.", {duration: 5000})
                form.current.reset();
            }, (error) => {
                console.log(error.text);
                toast.success("Failed to send message, please try again", {duration: 5000})
            });
    };

    return (
        <>
            <div className={'container'}>
                <div className={'contact-us-body-container'}>
                    <div className={'contact-us-body'}>
                        <p className={'contact-us-body-heading'}>How can we help?</p>

                        <p className={'contact-us-body-sub-heading'}>It makes us happy to meet new people! Let’s set up a
                            (digital)
                            meeting to talk about you. Please fill in
                            the
                            form
                            below and we’ll be in touch.</p>

                        <p className={'contact-us-send-email-text'}>Or, if you prefer,&nbsp;
                            <a href="mailto: office@e-commercecaptain.com">send us an email</a></p>

                        <form ref={form}>
                            <p className={'contact-us-input-label'}>Hello, my name is</p>
                            <input className={'contact-us-input form-control'} placeholder={'Full Name'} type="text" name="name"/>

                            <p className={'contact-us-input-label'}>You can reach me at:</p>
                            <input className={'contact-us-input form-control'} placeholder={'Email'} type="text" name="email"/>

                            <p className={'contact-us-input-label'}>The company I work with is:</p>
                            <input className={'contact-us-input form-control'} placeholder={'Company Name'} type="text"
                                   name="company_name"/>

                            <p className={'contact-us-input-label'}>I need help with:</p>
                            <textarea className={'contact-us-input form-control'} placeholder={'Brief Project Description'}
                                      name="message"/>
                        </form>

                        <AnimationBtn
                            text={"Send Message"}
                            outerDiv={'hero-btn-service'}
                            btnClass={'hero-service-btn'}
                            btnDivClass={'hero-service-btn-div'}
                            onClick={sendEmail}
                        />

                    </div>
                </div>
            </div>


            <ContactUsSwiper/>

            <div className={'container'}>
                <div className={'contact-us-body-container my-5'}>
                    <div className={'contact-us-body'}>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <p className={'contact-info-heading'}>Say Hello</p>
                                <p className={'contact-info-text'}>e-commercecaptain.com</p>
                                <p className={'contact-info-text'}>+31 20 210 1455</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <p className={'contact-info-heading'}>Office</p>
                                <p className={'contact-info-text'}>Willemsparkweg 63</p>
                                <p className={'contact-info-text'}>1071 GS Amsterdam</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <p className={'contact-info-heading'}>Careers</p>
                                <p className={'contact-info-text'}>talent@storyofams.com</p>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <p className={'contact-info-heading'}>Press</p>
                                <p className={'contact-info-text'}>pr@storyofams.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ContactUsBody;