import React from 'react';
import {AiOutlineArrowRight} from "react-icons/ai";
import clsx from "clsx";

function CasePromo({cardTitle, cardTitleMeta,features, casePromoContainer, columnClass, caseImg, casePromoTextContainerClass}) {
    return (
        <div className={'container'}>
            <div className={clsx('row g-0 case-promo-container', casePromoContainer)}>
                <div className={'case-promo-side-shape'}/>

                <div className={columnClass}>
                    <div className={'case-promo-image-container'}>
                        <img src={caseImg} className={'case-promo-image'}/>
                    </div>
                </div>

                <div className={clsx(casePromoTextContainerClass, 'case-promo-text-container shadow')}>
                    <p className={'case-promo-heading-meta'}>{cardTitleMeta}</p>
                    <p className={'case-promo-heading'}>{cardTitle}<span
                        className={'case-promo-heading-dot'}>.
                    </span></p>

                    {features}

                    {/*<p className={'view-case-text'}>*/}
                    {/*    View Case <span className={'view-case-text-arrow'}><AiOutlineArrowRight/></span>*/}
                    {/*</p>*/}

                </div>

            </div>
        </div>
    );
}

export default CasePromo;