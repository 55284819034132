import React from 'react';
import Layout from "../Component/Layout/Layout";
import AnimationBtn from "../Component/website/AnimationBtn";

function AboutUs() {
    return (
        <Layout>

            <div className={'container'}>
                <div className={'contact-us-body-container'}>
                    <div className={'contact-us-body'}>
                        <p className={'contact-us-body-heading'}>About Us</p>

                        <p className={'contact-us-body-sub-heading'}>Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop pu</p>
                        <p className={'contact-us-body-sub-heading'}>It makes us happy to meet new people! Let’s set up a
                            (digital)
                            meeting to talk about you. Please fill in
                            the
                            form
                            below and we’ll be in touch.</p>
                        <p className={'contact-us-body-sub-heading'}>It makes us happy to meet new people! Let’s set up a
                            (digital)
                            meeting to talk about you. Please fill in
                            the
                            form
                            below and we’ll be in touch.</p>
                        <p className={'contact-us-body-sub-heading'}>Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop pu</p>

                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default AboutUs;