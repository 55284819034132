import React from 'react';
import Layout from "../Component/Layout/Layout";
import Story from "../Component/Layout/Story";
import Footer from "../Component/Layout/Footer";
import CasePromo from "../Component/website/CasePromo";
import casePromo2 from "../assets/images/case-cover-2.webp";
import casePromo1 from "../assets/images/case-promo-1.webp";
import casePromo4 from "../assets/images/cases-cover4.webp";
import casePromo6 from "../assets/images/cases-cover6.webp";

function Cases() {
    return (
        <Layout>

            <div className="container">
                <p className={'cases-title'}>
                    With our clients, for our clients<span style={{color: "rgb(24, 236, 192)"}}
                >.</span>
                </p>

                <p className={'cases-title-meta'}>
                    Get insights into how we work and what our clients have to say.</p>
            </div>

            <CasePromo
                cardTitleMeta={'BOLS'}
                cardTitle={'Goings direct to consumer with Shopify Plus'}
                features={
                    <p className={'case-promo-features'}>
                        Design <span className={'case-promo-features-dot'}>.</span> Development <span
                        className={'case-promo-features-dot'}>.</span> Headless Shopify <span
                        className={'case-promo-features-dot'}>.</span> Branding
                    </p>
                }

                casePromoContainer={'case-promo-container-1'}
                columnClass={"col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"}
                caseImg={casePromo1}
                casePromoTextContainerClass={'case-promo-text-container-1'}
            />

            <CasePromo
                cardTitleMeta={'NATURAL HEROS'}
                cardTitle={'Building a smarter experience with Shopify Plus'}
                features={
                    <p className={'case-promo-features'}>
                        Branding <span className={'case-promo-features-dot'}>.</span> Design <span
                        className={'case-promo-features-dot'}>.</span> Development <span
                        className={'case-promo-features-dot'}>.</span> Headless Shopify
                    </p>
                }

                casePromoContainer={'case-promo-container-2'}
                columnClass={"col-xl-10 offset-xl-2 offset-lg-2 col-lg-10 offset-md-2 col-md-10 col-sm-12 col-xs-12 col-12"}
                caseImg={casePromo2}
                casePromoTextContainerClass={'case-promo-text-container-2'}
            />

            <CasePromo
                cardTitleMeta={'STUDIO NOOS'}
                cardTitle={'Upgrading to Shopify Dawn Theme'}
                features={
                    <p className={'case-promo-features'}>
                        Strategy <span className={'case-promo-features-dot'}>.</span> Design <span
                        className={'case-promo-features-dot'}>.</span> Development
                    </p>
                }

                casePromoContainer={'case-promo-container-1'}
                columnClass={"col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"}
                caseImg={casePromo4}
                casePromoTextContainerClass={'case-promo-text-container-1'}
            />

            <CasePromo
                cardTitleMeta={'SIGNIFY'}
                cardTitle={'The new standard'}
                features={
                    <p className={'case-promo-features'}>
                        Design <span className={'case-promo-features-dot'}>.</span> Development <span
                        className={'case-promo-features-dot'}>.</span> Headless Shopify <span
                        className={'case-promo-features-dot'}>.</span> Branding
                    </p>
                }

                casePromoContainer={'case-promo-container-1'}
                columnClass={"col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"}
                caseImg={casePromo1}
                casePromoTextContainerClass={'case-promo-text-container-1'}
            />

            <CasePromo
                cardTitleMeta={'SIGNIFY'}
                cardTitle={'The new standard'}
                features={
                    <p className={'case-promo-features'}>
                        Design <span className={'case-promo-features-dot'}>.</span> Development <span
                        className={'case-promo-features-dot'}>.</span> Headless Shopify <span
                        className={'case-promo-features-dot'}>.</span> Branding
                    </p>
                }

                casePromoContainer={'case-promo-container-1'}
                columnClass={"col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"}
                caseImg={casePromo6}
                casePromoTextContainerClass={'case-promo-text-container-1'}
            />


        </Layout>
    );
}

export default Cases;