import React, {useState} from 'react';
import clsx from "clsx";
import NavbarSM from "./NavbarSM";
import Hero from "./Hero";
import Story from "./Story";
import Footer from "./Footer";

function Layout({children}) {
    const [openSideBar, setOpenSideBar] = useState(false)

    const openSidebarHandle = () => {
        setOpenSideBar(!openSideBar)
    }

    return (
        <div className={clsx('layout', openSideBar && "landing-page-noscroll")}>

            <NavbarSM openSideBar={openSideBar} openSidebarHandle={openSidebarHandle}/>

            <Hero openSidebarHandle={openSidebarHandle}/>

            {children}

            <div className="container">
                <hr/>
            </div>

            <Story/>

            <Footer/>
        </div>
    );
}

export default Layout;