import React from 'react';
import AnimationBtn from "../website/AnimationBtn";
import {useNavigate} from "react-router-dom";

function Story({}) {
    const navigate = useNavigate();

    return (
        <div className={'story-container'}>
            <div className={'background-gradient'}/>
            <div className={'story-inner-container container'}>
                <p className={'story-title'}>
                    Let's write your story!
                </p>
                <p className={'story-text'}>
                    We don't tell you our story. We write it together. Let’s schedule a call and discover how we can help.
                </p>
                <div className={'hero-btn-container'}>
                    <AnimationBtn
                        text={"Contact US"}
                        outerDiv={'story-btn-service'}
                        btnClass={'hero-work-btn'}
                        btnDivClass={'hero-work-btn-div'}
                        onClick={() => {
                            navigate('/contact-us/')
                        }}
                    />

                    {/*<AnimationBtn*/}
                    {/*    text={"Subscribe to newsletter"}*/}
                    {/*    outerDiv={'hero-btn-work'}*/}
                    {/*    btnClass={'see-btn'}*/}
                    {/*    btnDivClass={'see-btn-btn-div'}*/}
                    {/*/>*/}
                </div>
            </div>
        </div>
    );
}

export default Story;