import React from "react";
import Sponsors from "../Component/LandingPage/Sponsors";
import CasePromo from "../Component/website/CasePromo";
import casePromo1 from "../assets/images/case-promo-1.webp";
import casePromo2 from "../assets/images/case-cover-2.webp";
import AnimationBtn from "../Component/website/AnimationBtn";
import CasePromoServices from "../Component/website/CasePromoServices";
import casePromo3 from "../assets/images/case-cover-3.webp";
import Layout from "../Component/Layout/Layout";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Sponsors />

      <CasePromo
        cardTitleMeta={"BOLS"}
        cardTitle={"Goings direct to consumer with Shopify Plus"}
        features={
          <p className={"case-promo-features"}>
            Design <span className={"case-promo-features-dot"}>.</span>{" "}
            Developmentsss <span className={"case-promo-features-dot"}>.</span>{" "}
            Headless Shopify{" "}
            <span className={"case-promo-features-dot"}>.</span> Branding
          </p>
        }
        casePromoContainer={
          "case-promo-container-1 landing-page-case-promo-container"
        }
        columnClass={"col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12"}
        caseImg={casePromo1}
        casePromoTextContainerClass={"case-promo-text-container-1"}
      />

      <CasePromo
        cardTitleMeta={"NATURAL HERO"}
        cardTitle={"Building a smarter experience with Shopify Plus"}
        features={
          <p className={"case-promo-features"}>
            Branding <span className={"case-promo-features-dot"}>.</span> Design{" "}
            <span className={"case-promo-features-dot"}>.</span> Development{" "}
            <span className={"case-promo-features-dot"}>.</span> Headlesss
            Shopify
          </p>
        }
        casePromoContainer={"case-promo-container-2"}
        columnClass={
          "col-xl-10 offset-xl-2 offset-lg-2 col-lg-10 offset-md-2 col-md-10 col-sm-12 col-xs-12 col-12"
        }
        caseImg={casePromo2}
        casePromoTextContainerClass={"case-promo-text-container-2"}
      />

      <div className={"d-flex mt-2 mb-5"}>
        <AnimationBtn
          text={"See More"}
          outerDiv={"see-btn-container"}
          btnClass={"see-btn"}
          btnDivClass={"see-btn-btn-div"}
          onClick={() => {
            navigate("/cases/");
          }}
        />
      </div>

      <div className="container">
        <hr />
      </div>

      <CasePromoServices
        promoTitle={"WHAT WE DO"}
        promoHeading={
          "Helping brands win the next decade of eCommerce with Shopify Plus"
        }
        promoFeatures={
          "We are a full-service eCommerce agency focused on building eCommerce experiences that will help you attract and connect with the customers that matter most."
        }
        casePromoContainer={"case-promo-container-3"}
        columnClass={"col-xl-10 col-lg-11 col-md-12 col-sm-12 col-xs-12 col-12"}
        caseImg={casePromo3}
        casePromoTextContainerClass={"case-promo-text-container-3"}
        btnText={"View our services"}
      />
    </Layout>
  );
}

export default LandingPage;
