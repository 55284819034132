import React from 'react';
import clsx from "clsx";
import {AiOutlineArrowRight} from "react-icons/ai";
import AnimationBtn from "./AnimationBtn";
import {useNavigate} from "react-router-dom";

function CasePromoServices({
                               promoTitle,
                               promoHeading,
                               promoFeatures,
                               casePromoContainer,
                               columnClass,
                               caseImg,
                               casePromoTextContainerClass,
                               btnText
                           }) {
    const navigate = useNavigate();

    return (
        <div className={'container'}>
            <div className={clsx('row g-0 case-promo-container', casePromoContainer)}>
                <div className={'case-promo-side-shape'}/>

                <div className={columnClass}>
                    <div className={'case-promo-image-container'}>
                        <img src={caseImg} className={'case-promo-image'}/>
                    </div>
                </div>

                <div id={'services'} className={clsx(casePromoTextContainerClass, 'case-promo-text-container shadow')}>
                    <p className={'case-promo-heading-meta'}>{promoTitle}</p>
                    <p className={'case-promo-heading'}>{promoHeading}<span
                        className={'case-promo-heading-dot'}>.
                    </span></p>

                    <p className={'case-promo-features'}>
                        {promoFeatures}
                    </p>

                    <p className={'view-case-text'}>
                        <AnimationBtn
                            text={btnText}
                            outerDiv={'hero-btn-service'}
                            btnClass={'hero-service-btn'}
                            btnDivClass={'hero-service-btn-div'}
                            onClick={() => {
                                navigate('/services/')
                            }}
                        />
                    </p>

                </div>

            </div>
        </div>
    );
}

export default CasePromoServices;