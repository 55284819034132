import React from 'react';
import Layout from "../Component/Layout/Layout";
import Story from "../Component/Layout/Story";
import Footer from "../Component/Layout/Footer";
import ContactUsBody from "../Component/ContactUs/ContactUsBody";

function ContactUs() {
    return (
        <Layout>

            <ContactUsBody />

        </Layout>
    );
}

export default ContactUs;