import React from 'react';
import sponsor from '../../assets/images/sponsor.webp';
import philips from '../../assets/images/philips-sponsor.webp';
import lucas from '../../assets/images/lucas-sponsor.webp';
import intersport from '../../assets/images/intersport-sponsor.webp';
import nike from '../../assets/images/nike-sponsor.webp';
import sig from '../../assets/images/sig-logokopie-sponsor.webp';

function Sponsors() {
    return (
        <div className={'sponsor-sm-container'}>
            <div className={'container'}>
                <p className={'sponsor-sm-text'}>We work with:</p>
                <div className={'sponsor-container'}>
                    <div className={'sponsor-sub-container'}>
                        <img src={sponsor} className={'sponsor-img'}/>
                        <img src={philips} className={'sponsor-img'}/>
                        <img src={lucas} className={'sponsor-img'}/>
                    </div>
                    <div className={'sponsor-sub-container'}>
                        <img src={intersport} className={'sponsor-img'}/>
                        <img src={nike} className={'sponsor-img sponsor-nike-img'}/>
                        <img src={sig} className={'sponsor-img'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sponsors;