import React from 'react';
import shopifyImg from '../../assets/images/shopify.png';
import {BsGithub, BsInstagram, BsLinkedin, BsTwitter} from "react-icons/bs";
import {useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate();

    return (
        <div className={'footer'}>
            <div className="container">

                <div className="row g-0 mb-5">
                    <div className="footer-xl col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <p className={'footer-title'}>Our partners - </p>
                            <img src={shopifyImg} className={'footer-partner-img'}/>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                        <p className={'footer-title'}>
                            Contact us -
                        </p>
                        <p className={'footer-text'} style={{marginBottom: 40}}>
                            hello@storyfams.com
                        </p>

                        <p className={'footer-title'}>
                            Office -
                        </p>
                        <p className={'footer-text sm-footer-text'}>
                            Willsparkweg 63 1071 GS Amsterdam
                        </p>

                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <p className={'footer-title'}>Company - </p>

                        <p onClick={() => {
                            navigate('/cases/')
                        }}
                           className={'footer-text'}>Cases</p>

                        <p onClick={() => {
                            navigate('/services/')
                        }}
                           className={'footer-text'}>Services</p>

                        <p onClick={() => {
                            navigate('/contact-us/')
                        }}
                           className={'footer-text'}>Contact Us</p>

                        <p onClick={() => {
                            navigate('/about-us/')
                        }}
                           className={'footer-text'}>About Us</p>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <p className={'footer-title'}>Resources - </p>
                        <p className={'footer-text'}>Shopify Plus</p>
                        <p className={'footer-text'}>Headless CMS</p>
                        <p className={'footer-text'}
                           style={{marginBottom: 40}}>
                            Instant Commerce
                        </p>
                        <div className="footer-xl">
                            <p className={'footer-title'}>Socials - </p>
                            <div style={{display: "flex"}}>
                                <BsInstagram className={'footer-icon'}/>
                                <BsLinkedin className={'footer-icon'}/>
                                <BsTwitter className={'footer-icon'}/>
                                <BsGithub className={'footer-icon'}/>
                            </div>
                        </div>
                    </div>

                    <div className="footer-sm col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <div>
                            <p className={'footer-title'}>Our partners - </p>
                            <img src={shopifyImg} className={'footer-partner-img'}/>
                        </div>
                    </div>
                    <div className="footer-sm col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
                        <p className={'footer-title'}>Socials - </p>
                        <div style={{display: "flex"}}>
                            <BsInstagram className={'footer-icon'}/>
                            <BsLinkedin className={'footer-icon'}/>
                            <BsTwitter className={'footer-icon'}/>
                            <BsGithub className={'footer-icon'}/>
                        </div>
                    </div>
                </div>

                <hr/>

                <div className={'below-footer-container'}>
                    <p className={'below-footer-text'}>Story of AMS B.V.</p>

                    <div className={'below-footer-container-2'}>
                        <p className={'below-footer-text'} style={{marginRight: 15}}>Terms & conditions</p>
                        <p className={'below-footer-text'}>Privacy Policy</p>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default Footer;