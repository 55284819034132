import React, {useEffect, useState} from 'react';
import {RxHamburgerMenu} from "react-icons/rx";
import clsx from "clsx";
import {IoIosCloseCircle} from "react-icons/io";
import smallLogo from '../../assets/images/small-icon.png';
import Sidebar from "./Sidebar";

function NavbarSm({openSideBar, openSidebarHandle}) {

    return (
        <div style={{position: "relative"}}>
            <div className={clsx('side-panel', openSideBar ? 'side-panel-active' : 'side-panel-inactive')}>
                <div className={clsx('side-panel-inner-container', !openSideBar && "display-item-none")}>
                    <div className={clsx('side-panel-background', !openSideBar && "display-item-none")}
                         onClick={openSidebarHandle}
                    />

                    <Sidebar openSideBar={openSideBar} openSidebarHandle={openSidebarHandle}/>
                </div>
            </div>

            <div className="navbar-sm navbar-sm-item">
                <div className="navbar-sm-logo-container">
                    <img src={smallLogo} className="navbar-sm-logo"/>
                </div>
                <div className="navbar-sm-ham-container">
                    <RxHamburgerMenu
                        onClick={openSidebarHandle}
                        className={'hamburger-icon'}/>
                </div>
            </div>
        </div>
    );
}

export default NavbarSm;