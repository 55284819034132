import React from 'react';
import styled, {keyframes} from 'styled-components';
import clsx from "clsx";

const moveAnimation = keyframes`
  25% {
    opacity: 0.3;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const moveDownAnimation = keyframes`
  25% {
    opacity: 0.3;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeroServiceBtn = styled.button`
  &:not(:hover) .hero-service-btn-text {
    animation: ${moveDownAnimation} 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }
  
  &:hover .hero-service-btn-text {
    animation: ${moveAnimation} 0.4s ease-in-out;
    animation-fill-mode: forwards;
  }
`;


function AnimationBtn({text, outerDiv, btnClass, btnDivClass, onClick, href}) {

    return (
        <div className={clsx(outerDiv, "hero-btn-inner-container")} onClick={onClick}>
            <a href={href} className="hero-btn-inner-link">
                <div>
                    <HeroServiceBtn className={clsx("hero-btn", btnClass)}>
                        <div className={clsx("hero-btn-div", btnDivClass)}></div>
                        <div className="hero-service-btn-text">{text}
                        </div>
                    </HeroServiceBtn>
                </div>
            </a>
        </div>
    );
}

export default AnimationBtn;