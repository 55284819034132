import React from 'react';
import clsx from "clsx";
import {IoIosCloseCircle, IoMdClose} from "react-icons/io";
import {useNavigate} from "react-router-dom";

function Sidebar({openSideBar, openSidebarHandle}) {
    const navigate = useNavigate();

    return (
        <div className={clsx('sidebar', !openSideBar && "display-item-none")}>

            <div className={'sidebar-div'}>
                <p
                    className={'sidebar-div-heading-text'}>MENU</p>
                <IoMdClose
                    onClick={openSidebarHandle}
                    style={{fontSize: 32, margin: "auto 10px auto auto", color: "#666666"}}/>
            </div>

            <div className={'sidebar-div'} onClick={() => {
                navigate('/')
            }}>
                <p
                    className={'sidebar-div-text'}>Home</p>
            </div>
            <div className={'sidebar-div'} onClick={() => {
                navigate('/cases/')
            }}>
                <p
                    className={'sidebar-div-text'}>Cases</p>
            </div>
            <div className={'sidebar-div'} onClick={() => {
                navigate('/services/')
            }}>
                <p
                    className={'sidebar-div-text'}>Services</p>
            </div>
            <div className={'sidebar-div'} onClick={() => {
                navigate('/about-us/')
            }}>
                <p
                    className={'sidebar-div-text'}>About Us</p>
            </div>
            <div className={'sidebar-div'} onClick={() => {
                navigate('/contact-us/')
            }}>
                <p
                    className={'sidebar-div-text'}>Contact Us</p>
            </div>
        </div>
    );
}

export default Sidebar;