import React from 'react';
import {AiOutlineArrowRight} from "react-icons/ai";

function ServicesPromoCase({cardImg, cardTitleMeta, cardTitle, features}) {
    return (
        <div className={'case-promo-container service-promo-case-container'}>
            <div className={'service-promo-case-img'}>
                <img src={cardImg}/>
            </div>

            <div className={'case-promo-text-container shadow service-promo-case-card'}>
                <p className={'case-study-card-services'}>Case Study</p>
                <p className={'card-services-heading-meta'}>{cardTitleMeta}</p>
                <p className={'card-services-heading-text'}>
                    {cardTitle}
                </p>
                {/*<p className={'view-case-text'} style={{color: "#111111"}}>*/}
                {/*    View Case <span className={'view-case-text-arrow'}><AiOutlineArrowRight/></span>*/}
                {/*</p>*/}
            </div>

        </div>
    );
}

export default ServicesPromoCase;