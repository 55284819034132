import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./Pages/landing-page";
import Cases from "./Pages/Cases";
import Services from "./Pages/Services";
import './assets/css/App.css';
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import {Toaster} from "react-hot-toast";


function App() {

    return (
        <BrowserRouter>
            <Toaster/>
            <Routes>
                <Route exact path={"/"} element={<LandingPage/>}/>

                <Route exact path={"/cases/"} element={<Cases/>}/>

                <Route exact path={"/services/"} element={<Services/>}/>

                <Route exact path={"/about-us/"} element={<AboutUs/>}/>

                <Route exact path={"/contact-us/"} element={<ContactUs/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
