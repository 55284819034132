import React, {useEffect, useState} from 'react';
import backgroundImage from '../../assets/images/background-image.png';
import Navbar from "./Navbar";
import HeroContent from "./HeroContent";
import Sponsors from "../LandingPage/Sponsors";
import {useLocation} from "react-router-dom";
import clsx from "clsx";

function Hero({openSidebarHandle}) {

    const [isLanding, setIsLanding] = useState(false)

    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/') {
            setIsLanding(true)
        }
    }, [location.pathname])

    return (
        <div className={clsx('hero', location.pathname !== '/' && 'hero-no-background')}>
            <div className={'background-gradient'}/>
            <div className="container">
                <div className="hero-content">
                    <Navbar openSidebarHandle={openSidebarHandle}/>
                    {isLanding &&
                        <HeroContent/>
                    }
                </div>
            </div>
        </div>
    );
}

export default Hero;