import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {Autoplay, Pagination} from "swiper";
import swiperImg1 from '../../assets/images/swiper-img-1.png';
import swiperImg2 from '../../assets/images/slideimg2.jpg';

function ContactUsSwiper({}) {
    const images = [
        swiperImg1,
        swiperImg2,
        swiperImg1,
        swiperImg2,
        swiperImg1,
        swiperImg2,
        swiperImg1,
        swiperImg2,
    ]

    return (
        <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            loop={true}
            speed={1000}
            autoplay={{
                delay: 5000,
                loop: true,
                disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className={"my-3 swiper-sm"}
        >
            {images.map((slide, index) => {
                return (
                    <SwiperSlide key={index}>
                        <img className={'slider-img'} src={slide} alt={"offer-img"}/>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default ContactUsSwiper;